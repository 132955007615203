import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import LoginScreen from "../features/auth/login/screens/login.screen";
import ForgotPasswordScreen from "../features/auth/reset-password/screens/forgot-password.screen";
import ResetPasswordScreen from "../features/auth/reset-password/screens/reset-password.screen";
import BookingDetailScreen from "../features/booking/screens/booking-detail.screen";
import BookingListScreen from "../features/booking/screens/booking-list.screen";
import CategoryEditScreen from "../features/category/screens/category-edit.screen";
import CategoryListScreen from "../features/category/screens/category-list.screen";
import SubcategoryEditScreen from "../features/category/screens/subcategory-edit-screen";
import EarningListScreen from "../features/earning/screens/earning-list.screen";
import ExperienceDetailScreen from "../features/experience/screens/experience-details.screen";
import ExperienceListScreen from "../features/experience/screens/experience-list.screen";
import GiftVoucherScreen from "../features/gift-voucher/screens/gift-voucher.screen";
import BannerCreateScreen from "../features/marketing/banner/screens/banner-create.screen";
import BannerEditScreen from "../features/marketing/banner/screens/banner-edit.screen";
import BannerListScreen from "../features/marketing/banner/screens/banner-list.screen";
import BestSellerListScreen from "../features/marketing/best-seller/screens/best-seller-list.screen";
import FeaturedExperienceListScreen from "../features/marketing/experience/screens/featured-experience-list.screen";
import ExploreSectionScreen from "../features/marketing/explore-section/screens/explore-section.screen";
import FestivalSectionEditScreen from "../features/marketing/festival-section/screens/festival-section-edit.screen";
import FestivalSectionListScreen from "../features/marketing/festival-section/screens/festival-section-list.screen";
import MerchantDetailScreen from "../features/merchant/screens/merchant-detail.screen";
import MerchantListScreen from "../features/merchant/screens/merchant-list.screen";
import PromoCodeCreateScreen from "../features/promo-code/screens/promo-code-create.screen";
import PromoCodeEditScreen from "../features/promo-code/screens/promo-code-edit.screen";
import PromoCodeListScreen from "../features/promo-code/screens/promo-code-list.screen";
import StatementDetailScreen from "../features/statement/screens/statement-details.screen";
import StatementListScreen from "../features/statement/screens/statement-list.screen";
import ReviewListScreen from "../features/review/screens/review-list.screen";
import TagCreateScreen from "../features/tag/screens/tag-create.screen";
import TagEditScreen from "../features/tag/screens/tag-edit.screen";
import TagListScreen from "../features/tag/screens/tag-list.screen";
import UserDetailScreen from "../features/user/screens/user-detail.screen";
import UserListScreen from "../features/user/screens/user-list.screen";
import AppHeader from "../infrastructure/layout/app-header.layout";
import PrivateRoute from "./private.routes";
import RestrictedRoute from "./restricted.routes";
import SearchTagListScreen from "../features/search-tag/screens/search-tag-list.screen";
import SearchTagCreateScreen from "../features/search-tag/screens/search-tag-create.screen";
import SearchTagEditScreen from "../features/search-tag/screens/search-tag-edit.screen";

function index() {
  return (
    <BrowserRouter>
      <Switch>
        <RestrictedRoute exact path="/login" component={() => <LoginScreen />} />
        <RestrictedRoute exact path="/forgot_password" component={() => <ForgotPasswordScreen />} />
        <RestrictedRoute
          exact
          path="/reset_password"
          component={(props) => <ResetPasswordScreen {...props} />}
        />
        <AppHeader>
          <PrivateRoute exact path="/" component={() => <MerchantListScreen />} />
          <PrivateRoute
            exact
            path="/merchant/merchant-list"
            component={() => <MerchantListScreen />}
          />
          <PrivateRoute
            exact
            path="/merchant/merchant-details"
            component={() => <MerchantDetailScreen />}
          />
          <PrivateRoute exact path="/user/user-list" component={() => <UserListScreen />} />
          <PrivateRoute exact path="/user/user-details" component={() => <UserDetailScreen />} />
          <PrivateRoute
            exact
            path="/experience/experience-list"
            component={() => <ExperienceListScreen />}
          />
          <PrivateRoute
            exact
            path="/experience/experience-detail"
            component={() => <ExperienceDetailScreen />}
          />

          <PrivateRoute
            exact
            path="/booking/booking-list"
            component={() => <BookingListScreen />}
          />

          <PrivateRoute
            exact
            path="/booking/booking-detail"
            component={() => <BookingDetailScreen />}
          />

          <PrivateRoute exact path="/category/list" component={() => <CategoryListScreen />} />
          <PrivateRoute exact path="/category/edit" component={() => <CategoryEditScreen />} />
          <PrivateRoute
            exact
            path="/category/subcategory/edit"
            component={() => <SubcategoryEditScreen />}
          />

          <PrivateRoute exact path="/tag/list" component={() => <TagListScreen />} />
          <PrivateRoute exact path="/tag/create" component={() => <TagCreateScreen />} />
          <PrivateRoute exact path="/tag/edit" component={() => <TagEditScreen />} />

          <PrivateRoute exact path="/search_tag/list" component={() => <SearchTagListScreen />} />
          <PrivateRoute
            exact
            path="/search_tag/create"
            component={() => <SearchTagCreateScreen />}
          />
          <PrivateRoute exact path="/search_tag/edit" component={() => <SearchTagEditScreen />} />

          <PrivateRoute
            exact
            path="/marketing/banner/list"
            component={() => <BannerListScreen />}
          />
          <PrivateRoute
            exact
            path="/marketing/banner/create"
            component={() => <BannerCreateScreen />}
          />
          <PrivateRoute
            exact
            path="/marketing/banner/edit"
            component={() => <BannerEditScreen />}
          />

          <PrivateRoute
            exact
            path="/marketing/featured-experience/list"
            component={() => <FeaturedExperienceListScreen />}
          />

          <PrivateRoute
            exact
            path="/marketing/festival-section/list"
            component={() => <FestivalSectionListScreen />}
          />
          <PrivateRoute
            exact
            path="/marketing/festival-section/edit"
            component={() => <FestivalSectionEditScreen />}
          />
          <PrivateRoute
            exact
            path="/marketing/explore-section"
            component={() => <ExploreSectionScreen />}
          />
          <PrivateRoute
            exact
            path="/marketing/best-seller"
            component={() => <BestSellerListScreen />}
          />
          <PrivateRoute exact path="/gift_voucher" component={() => <GiftVoucherScreen />} />
          <PrivateRoute exact path="/promo_code/list" component={() => <PromoCodeListScreen />} />
          <PrivateRoute
            exact
            path="/promo_code/create"
            component={() => <PromoCodeCreateScreen />}
          />
          <PrivateRoute exact path="/promo_code/detail" component={() => <PromoCodeEditScreen />} />
          <PrivateRoute exact path="/statement/list" component={() => <StatementListScreen />} />
          <PrivateRoute
            exact
            path="/statement/detail"
            component={() => <StatementDetailScreen />}
          />
          <PrivateRoute exact path="/earning/list" component={() => <EarningListScreen />} />
          <PrivateRoute exact path="/review/list" component={() => <ReviewListScreen />} />
        </AppHeader>
      </Switch>
    </BrowserRouter>
  );
}

export default index;
