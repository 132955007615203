import React from "react";
import { useTheme } from "@mui/material/styles";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
// import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ListIcon from "@mui/icons-material/List";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  List,
  ListItem,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import RateReviewIcon from "@mui/icons-material/RateReview";
import Spacer from "../../components/spacer.component";
import Text from "../../components/text.component";
import MarketingIcon from "../../assets/images/marketing-icon";
import ExperiencesIcon from "../../assets/images/experiences-icon";
import routes from "../../navigation/routes";
import TagIcon from "../../assets/images/tag-icon";
import GiftVoucherIcon from "../../assets/images/gift-voucher-icon";
import BookingIcon from "../../assets/images/booking-icon";

const NavigationButtonBox = styled(Box)({
  padding: "0x",
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  textTransform: "none",
  marginTop: "5px",
});

const StyledIcon = styled(Icon)({
  fontSize: "22px",
});

const StyledListItem = styled(ListItem)({
  ":hover": { backgroundColor: "transparent" },
});

function DrawerItems({ setOpenDrawer }) {
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();

  return (
    <List sx={{ paddingBottom: "50px", paddingTop: "25px" }}>
      <StyledListItem
        button
        onClick={() => {
          history.push(routes.MERCHANTLIST);
          setOpenDrawer(false);
        }}
      >
        <NavigationButtonBox>
          <StyledIcon
            sx={{
              color:
                location.pathname.toUpperCase().includes("MERCHANT") || location.pathname === "/"
                  ? theme.palette.colors.brand.primary
                  : theme.palette.colors.brand.secondary,
            }}
          >
            <PeopleAltIcon />
          </StyledIcon>
          <Spacer position="right" size="m" />
          <Text
            type={
              location.pathname.toUpperCase().includes("MERCHANT") || location.pathname === "/"
                ? "BrandColor"
                : null
            }
            sx={{ fontSize: theme.fonts.fontSizes.size18 }}
          >
            Merchants
          </Text>
        </NavigationButtonBox>
      </StyledListItem>
      <StyledListItem
        button
        onClick={() => {
          history.push(routes.USERLIST);
          setOpenDrawer(false);
        }}
      >
        <NavigationButtonBox>
          <StyledIcon
            sx={{
              color: location.pathname.toUpperCase().includes("USER")
                ? theme.palette.colors.brand.primary
                : theme.palette.colors.brand.secondary,
            }}
          >
            <PeopleAltIcon />
          </StyledIcon>
          <Spacer position="right" size="m" />
          <Text
            type={location.pathname.toUpperCase().includes("USER") ? "BrandColor" : null}
            sx={{ fontSize: theme.fonts.fontSizes.size18 }}
          >
            Users
          </Text>
        </NavigationButtonBox>
      </StyledListItem>
      <StyledListItem
        button
        onClick={() => {
          history.push(routes.EXPERIENCELIST);
          setOpenDrawer(false);
        }}
      >
        <NavigationButtonBox>
          <ExperiencesIcon
            isSelected={
              location.pathname.toUpperCase().includes("EXPERIENCE") &&
              location.pathname !== routes.MARKETING_FEATURED_EXPERIENCE_LIST
            }
          />

          <Spacer position="right" size="m" />
          <Text
            type={
              location.pathname.toUpperCase().includes("EXPERIENCE") &&
              location.pathname !== routes.MARKETING_FEATURED_EXPERIENCE_LIST
                ? "BrandColor"
                : null
            }
            sx={{ fontSize: theme.fonts.fontSizes.size18 }}
          >
            Experiences
          </Text>
        </NavigationButtonBox>
      </StyledListItem>
      <StyledListItem
        button
        onClick={() => {
          history.push(routes.BOOKING_LIST);
          setOpenDrawer(false);
        }}
      >
        <NavigationButtonBox>
          <BookingIcon isSelected={location.pathname.toUpperCase().includes("BOOKING")} />

          <Spacer position="right" size="m" />
          <Text
            type={location.pathname.toUpperCase().includes("BOOKING") ? "BrandColor" : null}
            sx={{ fontSize: theme.fonts.fontSizes.size18 }}
          >
            Bookings
          </Text>
        </NavigationButtonBox>
      </StyledListItem>
      <StyledListItem
        button
        onClick={() => {
          history.push(routes.CATEGORY_LIST);
          setOpenDrawer(false);
        }}
      >
        <NavigationButtonBox>
          <StyledIcon
            sx={{
              color: location.pathname.toUpperCase().includes("CATEGORY")
                ? theme.palette.colors.brand.primary
                : theme.palette.colors.brand.secondary,
            }}
          >
            <ListIcon />
          </StyledIcon>
          <Spacer position="right" size="m" />
          <Text
            type={location.pathname.toUpperCase().includes("CATEGORY") ? "BrandColor" : null}
            sx={{ fontSize: theme.fonts.fontSizes.size18 }}
          >
            Categories
          </Text>
        </NavigationButtonBox>
      </StyledListItem>

      <StyledListItem
        button
        onClick={() => {
          history.push(routes.TAG_LIST);
          setOpenDrawer(false);
        }}
      >
        <NavigationButtonBox>
          <TagIcon
            isSelected={
              location.pathname.toUpperCase().includes("TAG") &&
              !location.pathname.toUpperCase().includes("SEARCH_TAG")
            }
          />
          <Spacer position="right" size="m" />
          <Text
            type={
              location.pathname.toUpperCase().includes("TAG") &&
              !location.pathname.toUpperCase().includes("SEARCH_TAG")
                ? "BrandColor"
                : null
            }
            sx={{ fontSize: theme.fonts.fontSizes.size18 }}
          >
            Tags
          </Text>
        </NavigationButtonBox>
      </StyledListItem>

      <StyledListItem
        button
        onClick={() => {
          history.push(routes.SEARCH_TAG_LIST);
          setOpenDrawer(false);
        }}
      >
        <NavigationButtonBox>
          <SearchIcon
            style={{
              color: location.pathname.toUpperCase().includes("SEARCH_TAG")
                ? theme.palette.colors.brand.primary
                : theme.palette.colors.brand.secondary,
            }}
          />
          <Spacer position="right" size="m" />
          <Text
            type={location.pathname.toUpperCase().includes("SEARCH_TAG") ? "BrandColor" : null}
            sx={{ fontSize: theme.fonts.fontSizes.size18 }}
          >
            Search Tags
          </Text>
        </NavigationButtonBox>
      </StyledListItem>

      <ListItem sx={{ padding: "0px" }}>
        <Accordion elevation={0}>
          <AccordionSummary>
            <NavigationButtonBox>
              <MarketingIcon isSelected={location.pathname.toUpperCase().includes("MARKETING")} />
              <Spacer position="right" size="m" />
              <Text
                type={location.pathname.toUpperCase().includes("MARKETING") ? "BrandColor" : null}
                sx={{ fontSize: theme.fonts.fontSizes.size18 }}
              >
                Marketing
              </Text>
              <Spacer position="right" size="m" />
              <KeyboardArrowDownIcon
                sx={{
                  fontSize: "18px",
                  color: location.pathname.toUpperCase().includes("MARKETING")
                    ? theme.palette.colors.brand.primary
                    : theme.palette.colors.brand.secondary,
                }}
              />
            </NavigationButtonBox>
          </AccordionSummary>
          <AccordionDetails>
            <StyledListItem
              button
              onClick={() => {
                history.push(routes.MARKETING_BANNER_LIST);
                setOpenDrawer(false);
              }}
            >
              <NavigationButtonBox>
                <Text
                  sx={{ fontSize: theme.fonts.fontSizes.size16 }}
                  type={
                    location.pathname.toUpperCase().includes("MARKETING/BANNER")
                      ? "BrandColor"
                      : "GreyColor"
                  }
                >
                  Marketing Banner
                </Text>
              </NavigationButtonBox>
            </StyledListItem>
            <StyledListItem
              button
              onClick={() => {
                history.push(routes.MARKETING_FEATURED_EXPERIENCE_LIST);
                setOpenDrawer(false);
              }}
            >
              <NavigationButtonBox>
                <Text
                  sx={{ fontSize: theme.fonts.fontSizes.size16 }}
                  type={
                    location.pathname.toUpperCase().includes("MARKETING/FEATURED-EXPERIENCE")
                      ? "BrandColor"
                      : "GreyColor"
                  }
                >
                  Marketing Experiences
                </Text>
              </NavigationButtonBox>
            </StyledListItem>
            <StyledListItem
              button
              onClick={() => {
                history.push(routes.MARKETING_FESTIVAL_SECTION_LIST);
                setOpenDrawer(false);
              }}
            >
              <NavigationButtonBox>
                <Text
                  sx={{ fontSize: theme.fonts.fontSizes.size16 }}
                  type={
                    location.pathname.toUpperCase().includes("MARKETING/FESTIVAL-SECTION")
                      ? "BrandColor"
                      : "GreyColor"
                  }
                >
                  Festival Section
                </Text>
              </NavigationButtonBox>
            </StyledListItem>

            <StyledListItem
              button
              onClick={() => {
                history.push(routes.MARKETING_EXPLORE_SECTION);
                setOpenDrawer(false);
              }}
            >
              <NavigationButtonBox>
                <Text
                  sx={{ fontSize: theme.fonts.fontSizes.size16 }}
                  type={
                    location.pathname.toUpperCase().includes("MARKETING/EXPLORE-SECTION")
                      ? "BrandColor"
                      : "GreyColor"
                  }
                >
                  Explore Section
                </Text>
              </NavigationButtonBox>
            </StyledListItem>

            <StyledListItem
              button
              onClick={() => {
                history.push(routes.MARKETING_BEST_SELLER);
                setOpenDrawer(false);
              }}
            >
              <NavigationButtonBox>
                <Text
                  sx={{ fontSize: theme.fonts.fontSizes.size16 }}
                  type={
                    location.pathname.toUpperCase().includes("MARKETING/BEST-SELLER")
                      ? "BrandColor"
                      : "GreyColor"
                  }
                >
                  Best Seller
                </Text>
              </NavigationButtonBox>
            </StyledListItem>
          </AccordionDetails>
        </Accordion>
      </ListItem>

      <StyledListItem
        button
        onClick={() => {
          history.push(routes.GIFT_VOUCHER);
          setOpenDrawer(false);
        }}
      >
        <NavigationButtonBox>
          <GiftVoucherIcon isSelected={location.pathname.toUpperCase().includes("GIFT_VOUCHER")} />

          <Spacer position="right" size="m" />
          <Text
            type={location.pathname.toUpperCase().includes("GIFT_VOUCHER") ? "BrandColor" : null}
            sx={{ fontSize: theme.fonts.fontSizes.size18 }}
          >
            Gift Voucher
          </Text>
        </NavigationButtonBox>
      </StyledListItem>
      <StyledListItem
        button
        onClick={() => {
          history.push(routes.PROMO_CODE_LIST);
          setOpenDrawer(false);
        }}
      >
        <NavigationButtonBox>
          <GiftVoucherIcon isSelected={location.pathname.toUpperCase().includes("PROMO_CODE")} />

          <Spacer position="right" size="m" />
          <Text
            type={location.pathname.toUpperCase().includes("PROMO_CODE") ? "BrandColor" : null}
            sx={{ fontSize: theme.fonts.fontSizes.size18 }}
          >
            Promo Codes
          </Text>
        </NavigationButtonBox>
      </StyledListItem>
      <StyledListItem
        button
        onClick={() => {
          history.push(routes.STATEMENT_LIST);
          setOpenDrawer(false);
        }}
      >
        <NavigationButtonBox>
          <RequestQuoteIcon
            style={{
              color: location.pathname.toUpperCase().includes("STATEMENT")
                ? theme.palette.colors.brand.primary
                : theme.palette.colors.brand.secondary,
            }}
          />
          <Spacer position="right" size="m" />
          <Text
            type={location.pathname.toUpperCase().includes("STATEMENT") ? "BrandColor" : null}
            sx={{ fontSize: theme.fonts.fontSizes.size18 }}
          >
            Statements
          </Text>
        </NavigationButtonBox>
      </StyledListItem>
      <StyledListItem
        button
        onClick={() => {
          history.push(routes.EARNING_LIST);
          setOpenDrawer(false);
        }}
      >
        <NavigationButtonBox>
          <MonetizationOnIcon
            style={{
              color: location.pathname.toUpperCase().includes("EARNING")
                ? theme.palette.colors.brand.primary
                : theme.palette.colors.brand.secondary,
            }}
          />
          <Spacer position="right" size="m" />
          <Text
            type={location.pathname.toUpperCase().includes("EARNING") ? "BrandColor" : null}
            sx={{ fontSize: theme.fonts.fontSizes.size18 }}
          >
            Earnings
          </Text>
        </NavigationButtonBox>
      </StyledListItem>
      <StyledListItem
        button
        onClick={() => {
          history.push(routes.REVIEW_LIST);
          setOpenDrawer(false);
        }}
      >
        <NavigationButtonBox>
          <RateReviewIcon
            sx={{
              color: location.pathname.toUpperCase().includes("REVIEW")
                ? theme.palette.colors.brand.primary
                : theme.palette.colors.brand.secondary,
            }}
          />
          <Spacer position="right" size="m" />
          <Text
            type={location.pathname.toUpperCase().includes("REVIEW") ? "BrandColor" : null}
            sx={{ fontSize: theme.fonts.fontSizes.size18 }}
          >
            Reviews
          </Text>
        </NavigationButtonBox>
      </StyledListItem>
    </List>
  );
}

DrawerItems.propTypes = {
  setOpenDrawer: PropTypes.func.isRequired,
};

export default DrawerItems;
