export default Object.freeze({
  HOME: "/",

  MERCHANTLIST: "/merchant/merchant-list",
  MERCHANTDETAIL: "/merchant/merchant-details",

  USERLIST: "/user/user-list",
  USERDETAIL: "/user/user-details",

  EXPERIENCELIST: "/experience/experience-list",
  EXPERIENCEDETAIL: "/experience/experience-detail",

  BOOKING_LIST: "/booking/booking-list",
  BOOKING_DETAIL: "/booking/booking-detail",

  MARKETING_BANNER_LIST: "/marketing/banner/list",
  MARKETING_BANNER_CREATE: "/marketing/banner/create",
  MARKETING_BANNER_EDIT: "/marketing/banner/edit",

  MARKETING_FEATURED_EXPERIENCE_LIST: "/marketing/featured-experience/list",

  MARKETING_FESTIVAL_SECTION_LIST: "/marketing/festival-section/list",
  MARKETING_FESTIVAL_SECTION_EDIT: "/marketing/festival-section/edit",

  MARKETING_EXPLORE_SECTION: "/marketing/explore-section",
  MARKETING_BEST_SELLER: "/marketing/best-seller",

  CATEGORY_LIST: "/category/list",
  CATEGORY_EDIT: "/category/edit",
  SUBCATEGORY_EDIT: "/category/subcategory/edit",

  TAG_LIST: "/tag/list",
  TAG_CREATE: "/tag/create",
  TAG_EDIT: "/tag/edit",

  SEARCH_TAG_LIST: "/search_tag/list",
  SEARCH_TAG_CREATE: "/search_tag/create",
  SEARCH_TAG_EDIT: "/search_tag/edit",

  LOGIN: "/login",
  FORGOTPASSWORD: "/forgot_password",
  RESETPASSWORD: "/reset_password",

  GIFT_VOUCHER: "/gift_voucher",

  PROMO_CODE_LIST: "/promo_code/list",
  PROMO_CODE_CREATE: "/promo_code/create",
  PROMO_CODE_DETAIL: "/promo_code/detail",

  STATEMENT_LIST: "/statement/list",
  STATEMENT_DETAIL: "/statement/detail",

  EARNING_LIST: "/earning/list",
  REVIEW_LIST: "/review/list",
});
