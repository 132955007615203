import { configureStore } from "@reduxjs/toolkit";
import authSliceServices from "./auth/auth-slice.services";
import bestSellerSliceServices from "./best-seller/best-seller.slice.services";
import bookingSliceServices from "./booking/booking.slice.services";
import categorySliceServices from "./category/category-slice.services";
import experienceSliceServices from "./experience/experience-slice.services";
import giftVoucherSliceServices from "./gift-voucher/gift-voucher-slice.services";
import bannerSliceServices from "./marketing/banner/banner-slice.services";
import exploreSectionSliceServices from "./marketing/explore-section/explore-section-slice.services";
import featuredExperienceSliceServices from "./marketing/featured-experience/featured-experience-slice.services";
import festivalSectionSliceServices from "./marketing/festival-section/festival-section-slice.services";
import merchantSliceServices from "./merchant/merchant-slice.services";
import promoCodeSliceServices from "./promo-code/promo-code-slice.services";
import statementSliceServices from "./statement/statement-slice.services";
import tagSliceServices from "./tag/tag-slice.services";
import earningSliceServices from "./earning/earning-slice.services";
import userSliceServices from "./user/user-slice.services";
import reviewSliceServices from "./review/review-slice.services";
import searchTagSliceServices from "./search-tag/search-tag-slice.services";

const store = configureStore({
  reducer: {
    auth: authSliceServices,
    user: userSliceServices,
    merchant: merchantSliceServices,
    experience: experienceSliceServices,
    category: categorySliceServices,
    banner: bannerSliceServices,
    tag: tagSliceServices,
    searchTag: searchTagSliceServices,
    featuredExperience: featuredExperienceSliceServices,
    festivalSection: festivalSectionSliceServices,
    exploreSection: exploreSectionSliceServices,
    bestSeller: bestSellerSliceServices,
    giftVoucher: giftVoucherSliceServices,
    booking: bookingSliceServices,
    promoCode: promoCodeSliceServices,
    statement: statementSliceServices,
    earning: earningSliceServices,
    review: reviewSliceServices,
  },
});

export default store;
